<template>
  <b-row>
    <b-col>
      <DxChart
        id="chart"
        :data-source="orders"
        :title="title ? title : 'Cantidad de trabajos por estado'"
      >
        <DxAnimation
          easing="easeOutCubic"
          :duration="500"
          :max-point-count-supported="100"
        />
        <DxArgumentAxis>
          <DxLabel />
        </DxArgumentAxis>

        <DxValueAxis>
          <DxTick :visible="false" />
          <DxLabel :visible="false" />
        </DxValueAxis>

        <DxSeries
          argument-field="name"
          value-field="value"
          type="bar"
          color="#00B398"
        >
          <DxLabel :visible="true" background-color="#071D49" />
        </DxSeries>

        <DxLegend :visible="false" />

        <DxExport :enabled="true" />
      </DxChart>
    </b-col>
  </b-row>
</template>
<style></style>
<script>
import DxChart, {
  DxArgumentAxis,
  DxExport,
  DxSeries,
  DxValueAxis,
  DxLabel,
  DxLegend,
  DxTick,
  DxAnimation,
} from "devextreme-vue/chart";
export default {
  components: {
    DxChart,
    DxArgumentAxis,
    DxExport,
    DxSeries,
    DxValueAxis,
    DxLabel,
    DxLegend,
    DxTick,
    DxAnimation,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    // title optional in the chart
    title: {
      type: String
    }
  },
};
</script>
